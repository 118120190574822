.result-container {
  display: flex;
  flex-direction: column;
  margin: .5rem;
  padding: 1rem;
  width: 100%;

  font-family: sans-serif;
  line-height: 20px;
  border: 1px solid #272727;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.result-item {
  margin: .25rem;
  padding: .25rem;
}

.result-container:hover {
  background-color: rgba(138, 3, 3, 0.685);
  color: white;
}

.result-container a {
  text-decoration: none;
  color: inherit;
}

.name {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: inherit;
}

.name a:hover {
  font-weight: 800
}

@media screen and (max-width: 1000px) {
  .name {
    text-align: center;
  }
}