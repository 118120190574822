/* Browser */
.header {
  position: fixed;
  background-color: #FFFFFF;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  z-index: 2;
}

.header button {
  border: none;
}

.header button:hover {
  cursor: pointer;
}

.header img {
  padding: 1.5rem;
  width: 12rem;
}

/* Mobile */
@media screen and (max-width: 1000px) {
  .header button {
    font-size: 15px;
    background-color: inherit;
  }

  .line {
    width: inherit;
  }
}