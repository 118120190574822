.choice-btn {
  margin: 2rem;
  padding: 2rem;

  border: 1px solid #272727;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #272727;
}

.choice-btn:hover {
  cursor: pointer;
  background-color: #8A0303;
  color: white;
}

@media screen and (max-width: 1000px) {
  .choice-btn { 
    padding: 1rem;
    margin: .75rem;
    
    width: 12rem;
    font-size: 1rem;
    line-height: 20px;
    background-color: inherit;
  }
}