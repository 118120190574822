* {
  padding: 0;
  margin: 0;
}

body, html, #root {
  height: 100vh;
  width: 100vw;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;

  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  /* border: 6px solid #8A0303; */
  box-sizing: border-box;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  flex-grow: 1;
  overflow: scroll;
}

.question-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 45px;
  justify-content: center;
  margin: 13rem auto;
  padding: 2rem 0;
  width: 80%;
  height: 20rem;
  align-items: center;
}

.current-question {
  width: auto;
  height: 38px;

  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 37px;
  /* identical to box height */

  color: #272727;
}

.choices-container {
  display: flex;
  width: auto;
}

@media screen and (max-width: 1000px) {
  .question-container {
    width: 100%;
    height: 60%;
    box-shadow: 0 0 0 0;
    border-radius: 0;
  }

  .main-content {
    margin: -5rem auto;
    padding: 0;
  }

  .current-question {
    padding: 0 0 1rem 0;
    line-height: 28px;
    text-align: center;
    font-size: 1.25rem;
  }

  .choices-container {
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    padding: 0;
  }

}


