.open-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
}

.open-btn::before {
  border-left: 350px solid rgba(138, 3, 3, 0.712); 
  border-top: 225px solid transparent; 
  position: absolute;
  bottom: -10px;
  left: -18px;
  content: ' ';
  z-index: 0;
}

.open-btn-text {
  color: white;
  width: 124px;
  margin-left: 14px;
  margin-bottom: 14px;
  font-size: 16px;
  text-align: left;
}

.open-btn-text p {
  color: white;
  position: relative;
  width: 150px;
  margin-left: .74rem;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.plus {
  position: relative;
  color: #FFFFFF;
  margin-left: .75rem;
  padding-bottom: .5rem;
}

.open-btn:hover {
  cursor: pointer;
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 0;
  width: 2rem;
  height: 2rem;
  padding: 0 0 1rem 0;
  opacity: 0.5;
  border: none;
}

.close-btn:hover {
  opacity: 1;
  cursor: pointer;
}

.close-btn:before, .close-btn:after {
  position: absolute;
  left: 1rem;
  content: ' ';
  height: 1.5rem;
  width: 2px;
  background-color: #333;
}
.close-btn:before {
  transform: rotate(45deg);
}
.close-btn:after {
  transform: rotate(-45deg);
}

.modal {
  position: fixed;
  top: 47%;
  left: 50%;
  margin: 0;
  padding: 2rem;
  width: 50vw;
  height: 13%;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  box-shadow: 1px 2px 5px grey;
  text-align: center;
  border: 1px solid rgb(138, 3, 3);
}

.modal-text {
  text-align: left;
  font-family: sans-serif;
  padding-top: 1rem;
  font-size: 18px;
}

.modal-text p a {
  text-decoration: none;
  color: #8A0303;
  font-weight: 700;
}

.modal-text p a:hover{
  text-shadow: 0 0 1px #999;
}

@media screen and (max-width: 1000px) {
  .open-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
  }

  .open-btn::before {

  }
}
