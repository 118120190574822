.start-over-btn {
margin: 2rem;
border: none;
font-family: sans-serif;
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 16px;
text-align: center;
text-transform: uppercase;

color: #BCBCBC;
}

.start-over-btn:hover {
  color: rgb(117, 116, 116);
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .start-over-btn { 
    z-index: 1;
    width: 40%;
    background-color: inherit;
  }
}

